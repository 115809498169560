import { ethers } from "ethers";
import BigNumber from "bignumber.js";
// import { getDisplayBalance, toLocaleString } from "utils/format";

const GAS_MUTIPLIER = 1.5;

// get gas estimate
const getGasEstimate = async (method, callOption, txOption) => {
  try {
    const gasEstimate = await method(...callOption).estimateGas(txOption);
    const gas = Math.ceil(gasEstimate * GAS_MUTIPLIER);

    // console.log("gas estimate", gasEstimate, gas);
    return gas;
  } catch (e) {
    console.log(e);
  }
};

export const getContractById = (link, contractId) => {
  let Contract;

  if (link) {
    switch (contractId) {
      case "usdt_proxy":
        Contract = link.contracts.usdt_proxy;
        break;
      case "usdt":
        Contract = link.contracts.usdt;
        break;
      default:
        break;
    }
  }

  return Contract;
};

// approve before link
export const approve = async (proxyContract, tokenContract, account) => {
  try {
    const callOption = [
      proxyContract.options.address,
      ethers.constants.MaxUint256,
    ];
    const txOption = { from: account };

    txOption.gas = await getGasEstimate(
      tokenContract.methods.approve,
      callOption,
      txOption
    );

    return tokenContract.methods.approve(...callOption).send(txOption);
  } catch (e) {
    console.log(e);
  }
};

// link token to nebulas net
export const stake = async (proxyContract, amount, toNebAddr, account) => {
  let depositAmount = new BigNumber(amount)
    .times(new BigNumber(10).pow(6))
    .toString();

  const callOption = [depositAmount, toNebAddr];
  const txOption = { from: account };
  txOption.gas = await getGasEstimate(
    proxyContract.methods.stake,
    callOption,
    txOption
  );

  console.log(txOption.gas);

  return proxyContract.methods.stake(...callOption).send(txOption);
};

export const getLatestTransferAccount = async (proxyContract, account) => {
  try {
    const latestNebAccount = await proxyContract.methods
      .getMappingAccount(account)
      .call();
    return latestNebAccount;
  } catch (e) {
    return "";
  }
};
