import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import Container from "components/Container";

import EventTable from "./components/EventTable";
import FilterInput from "./components/FilterInput";
import FilterTab from "./components/FilterTab"

import useEvent from "hooks/useEvent";

const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

const networkList = [
  {
    id: "eth",
    name: chain_id === 1 ? "Ethereum" : "Ethereum Kovan",
    type: 1, // eth -> neb
  },
  {
    id: "neb",
    name: chain_id === 1 ? "Nebulas": "Nebulas Testnet",
    type: 2, // neb -> eth
  },
];

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Event = () => {
  const { t } = useTranslation();
  const [filterSelect, setFilterSelect] = useState(networkList[0]);
  const [filterValue, setFilterValue] = useState("");



  const { eventData, totalEvent, fetchEventData, fetching } = useEvent();

  const query = useQuery();

  useEffect(() => {
    let type, key;
    if (query.get("type")) {
      type = query.get("type");
      if (type === "1" || type === "2") {
        // console.log("setFilterSelect", type);
        setFilterSelect(networkList[parseInt(type) - 1]);
      }
    }

    if (query.get("key")) {
      key = query.get("key");
      setFilterValue(key);
    }

    fetchEventData({
      type,
      key,
    });
  }, []);

  const onFetchData = ({
    type,
    key,
  }) => {

    if (type === "1" || type === "2") {
      // console.log("setFilterSelect", type);
      setFilterSelect(networkList[parseInt(type) - 1]);
    }

    setFilterValue(key);

    fetchEventData({
      type,
      key,
    });


  }

  return (
    <Container layout="body">
      <StyledWrapper>
        <StyledHead>
          <StyledLeft>
            <StyledTitle>{t("events")}</StyledTitle>
            <FilterTab 
              networkList={networkList}
              select={filterSelect}
              onSelect={setFilterSelect}
              address={filterValue}
              fetchData={onFetchData}
            />
          </StyledLeft>
          <StyledFilter>
            <FilterInput
              networkList={networkList}
              select={filterSelect}
              onSelect={setFilterSelect}
              fetchData={onFetchData}
              address={filterValue}
              setAddress={setFilterValue}
            />
          </StyledFilter>
        </StyledHead>
        <EventTable
          data={eventData}
          total={totalEvent}
          type={filterSelect.type}
          fetching={fetching}
          fetchData={fetchEventData}
        />
      </StyledWrapper>
    </Container>
  );
};

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 5rem;
  min-height: 200px;
  max-height: 850px;
  width: 100%;
  padding: 30px;
  background: #ffffff;
  overflow-x: auto;
  box-shadow: 0px 0px 10px 0px rgba(134, 141, 167, 0.15);

  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-top: 2rem;
`}
`;

const StyledHead = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 30px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction:column;
  `}
`;

const StyledLeft = styled(Flex)`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction:column;
  align-items: flex-start;
`}

`;

const StyledTitle = styled(Text)`
  margin-right: 30px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 1rem;
  `}
`;

const StyledFilter = styled(Box)``;

export default Event;
