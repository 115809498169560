import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Container from "components/Container";

import Button from "components/Button";

import AccountButton from "components/AccountButton";
import ETHTokenInput from "./components/ETHTokenInput";
import NebTokenInput from "./components/NebTokenInput";
import RecipientAddrInput from "./components/RecipientAddrInput";
import SwapInfo from "./components/SwapInfo";

import SwitchDirectionButton from "./components/SwitchDirectionButton";

import { useWallet } from "use-wallet";

// ether
import useBalance from "hooks/useBalance";
import useLatestTransferAccount from "hooks/useLatestTransferAccount";

// neb
import useNebAccount from "hooks/useNebAccount";
import useNebAllowance from "hooks/useNebAllowance";
import useNebBalance from "hooks/useNebBalance";
import useNebLatestTransferAccount from "hooks/useNebLatestTransferAccount";

// swap button
import ETHSwapButton from "./components/ETHSwapButton";
import NebSwapButton from "./components/NebSwapButton";

import { transparentize } from "polished";

// utils
import { basicToNUSDT } from "utils/nebFormat";

import useBridgeFee from "hooks/useBridgeFee";

const Home = ({ direction = "eth-to-nas" }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  const [swapDirection, setSwapDirection] = useState(direction);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [inputDisabled, setInputDisabled] = useState(true);
  const [inputError, setInputError] = useState("");

  const [recpAddr, setRecpAddr] = useState("");
  const [recpAddrInvalid, setRecpAddrInvalid] = useState(true);

  const [nebNeedApprove, setNebNeedApprove] = useState(false);

  // eth
  const { account } = useWallet();
  const { latestAcc, fetchLastestTransferAccount } = useLatestTransferAccount();
  const { balance, fetchingBalance } = useBalance();

  // neb
  const { nebAccount } = useNebAccount();
  const { nebAllowance, fetchNebAllowance } = useNebAllowance(nebAccount);
  const { nebBalance, fetchingNebBalance, fetchNebBalance } = useNebBalance(
    nebAccount
  );
  const { ethAccount: latestEthAccount } = useNebLatestTransferAccount();

  // get eth gas price,
  const { feeData } = useBridgeFee();

  useEffect(() => {
    if (nebAccount) {
      console.log("get nebAccount", nebAccount);
    }
  }, [nebAccount]);

  // set default default neb recp address
  useEffect(() => {
    if (latestAcc) {
      setRecpAddr(latestAcc);
      setRecpAddrInvalid(false);
    }
  }, [latestAcc]);

  // set default default eth recp address
  useEffect(() => {
    if (latestEthAccount) {
      setRecpAddr(latestEthAccount);
      setRecpAddrInvalid(false);
    }
  }, [latestEthAccount]);

  // set default recipient address, if load wallet account
  //   useEffect(() => {
  //     if (swapDirection === "eth-to-nas") {
  //       setRecpAddr(nebAccount);
  //     }
  //   }, [swapDirection, nebAccount]);

  function handleClickChangeSwapDirection() {
    // clear from, to input value
    setFromValue("");
    setToValue("");
    // clear recipient addr
    setRecpAddr("");

    // clear(re-init) swap button status
    setRecpAddrInvalid(true);
    setInputError("");

    if (swapDirection === "eth-to-nas") {
      setSwapDirection("nas-to-eth");
      history.push("/nas-to-eth");

      // fetch neb account
      // fetchNebAccount();

      //   setRecpAddr(account);
      // get latest allowance, balance
      //   fetchNebAllowance();

      //   console.log("nebAllowance", nebAllowance);
      //   // check is need approve
      //   if (nebAllowance > 0) {
      //     setNebNeedApprove(false);
      //   }
    } else {
      setSwapDirection("eth-to-nas");
      history.push("/");
      //   setRecpAddr(nebAccount);
    }
  }

  const onChangeFromInput = (value) => {
    setFromValue(value);

    const ratio = 1;
    setToValue(ratio * value);

    if (swapDirection === "nas-to-eth") {
      // update neb need approve status
      checkNebNeedApprove(value);
    }

  };

  const onChangeRecpAddrInput = (value) => {
    setRecpAddr(value);
  };

  const checkNebNeedApprove = (value) => {
    console.log(
      "checkNebNeedApprove",
      nebAllowance,
      Number(basicToNUSDT(nebAllowance)),
      value
    );
    if (nebAllowance === 0) {
      setNebNeedApprove(true);
    } else if (
      value > Number(basicToNUSDT(nebAllowance)) &&
      value <= Number(nebBalance)
    ) {
      setNebNeedApprove(true);
    } else {
      setNebNeedApprove(false);
    }
  };

  const checkInputValid = useCallback( (value) => {
    // clear input error
    setInputError("");
    setInputDisabled(false);

    if (swapDirection === "eth-to-nas") {
      //   console.log(
      //     "eth checkInputValid",
      //     value,
      //     balance,
      //     Number(value) > Number(balance)
      //   );
      // check input > balance
      if (Number(value) > Number(balance)) {
        setInputDisabled(true);
        setInputError(t("insufficient-balance"));
      }
    } else {
      // check fee
      if (feeData) {
        const at_least_fee = Number(feeData.atLeastFeeUSD);
        if (at_least_fee > Number(value)) {
          setInputDisabled(true);
          setInputError(
            t("swap-at-least", { amount: at_least_fee, token: "nUSDT" })
          );
        }
      }

      // check swap value
      const MIN_SWAP_USDT = 5;
      // neb -> eth
      // min link nUSDT = 5
      if (Number(value) < MIN_SWAP_USDT) {
        setInputDisabled(true);
        setInputError(
          t("swap-at-least", { amount: MIN_SWAP_USDT, token: "nUSDT" })
        );
      }

      // check input > balance
      if (Number(value) > Number(nebBalance)) {
        setInputDisabled(true);
        setInputError(t("insufficient-balance"));
      }
    }
  },[balance, nebBalance, feeData, swapDirection, t]);


  useEffect(() => {

    if(fromValue) {
      checkInputValid(fromValue);
    }

  },[fromValue, balance, nebBalance, feeData])

  return (
    <Container layout="body">
      <StyledWrapper>
        <StyledCard>
          <StyledCardContainer>
            <StyledCardHead>
              <StyledTitle>
                {t("select-between-ethereum-and-nebulas")}
              </StyledTitle>

              <BetaMessage>
                {parseInt(chain_id) === 1
                  ? t("use-at-your-own-risk")
                  : t("beta-testnet")}
              </BetaMessage>

              {swapDirection === "eth-to-nas" && !!account && (
                <AccountButton type="eth" account={account} />
              )}

              {swapDirection === "nas-to-eth" && !!nebAccount && (
                <AccountButton type="neb" account={nebAccount} />
              )}
            </StyledCardHead>

            <StyledSwapContainer>
              {swapDirection === "eth-to-nas" ? (
                <ETHTokenInput
                  type="from"
                  value={fromValue}
                  balance={balance}
                  fetching={fetchingBalance}
                  onChange={onChangeFromInput}
                />
              ) : (
                <NebTokenInput
                  type="from"
                  value={fromValue}
                  balance={nebBalance}
                  fetching={fetchingNebBalance}
                  onChange={onChangeFromInput}
                />
              )}

              {/* change swap direction */}
              <SwitchDirectionButton
                onSwitchClick={handleClickChangeSwapDirection}
              />

              {swapDirection === "eth-to-nas" ? (
                <NebTokenInput type="to" value={toValue} />
              ) : (
                <ETHTokenInput type="to" value={toValue} />
              )}

              {/* show price, fee */}
              <SwapInfo direction={swapDirection} feeData={feeData} />

              {swapDirection === "eth-to-nas" && !!account && (
                <RecipientAddrInput
                  type="neb"
                  value={recpAddr}
                  setInvalid={setRecpAddrInvalid}
                  onChange={onChangeRecpAddrInput}
                />
              )}

              {swapDirection === "nas-to-eth" && !!nebAccount && (
                <RecipientAddrInput
                  type="eth"
                  value={recpAddr}
                  setInvalid={setRecpAddrInvalid}
                  onChange={onChangeRecpAddrInput}
                />
              )}
            </StyledSwapContainer>

            {/* swapDirection = "eth-to-nas" */}
            {swapDirection === "eth-to-nas" && (
              <ETHSwapButton
                params={{
                  fromValue,
                  recpAddr,
                  inputDisabled,
                  recpAddrInvalid,
                  inputError,
                }}
              />
            )}

            {/* swapDirection = "nas-to-eth" */}
            {swapDirection === "nas-to-eth" && (
              <NebSwapButton
                params={{
                  recpAddr,
                  fromValue,
                  nebNeedApprove,
                  inputDisabled,
                  recpAddrInvalid,
                  inputError,
                }}
              />
            )}
          </StyledCardContainer>
        </StyledCard>
      </StyledWrapper>
    </Container>
  );
};

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-top: 2rem;
`}
`;

const StyledCard = styled(Box)`
  padding: 25px 15px;
  max-width: 420px;
  min-height: 428px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(134, 141, 167, 0.15);
`;

const StyledCardContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledCardHead = styled(Flex)`
  flex-direction: column;
`;

const StyledTitle = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.color.text1};
`;

const StyledSwapContainer = styled(Flex)`
  flex-direction: column;
  margin: 20px 0;
`;

const BetaMessage = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  flex: 1 0 auto;
  align-items: center;
  position: relative;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  border: 1px solid ${({ theme }) => transparentize(0.6, theme.wisteriaPurple)};
  background-color: ${({ theme }) => transparentize(0.9, theme.wisteriaPurple)};
  border-radius: 0rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
  color: ${({ theme }) => theme.wisteriaPurple};
`;

export default Home;
