import React from "react";
import TokenInput from "components/TokenInput";

import usdt from "assets/icon/usdt.png";

const ETHTokenInput = ({ type, onChange, value, balance, fetching }) => {

  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  const tokenList = [
    {
      id: "usdt",
      name: "USDT",
      logo: usdt,
    },
  ];

  return (
    <TokenInput
      type={type}
      onChange={onChange}
      value={value}
      balance={balance}
      fetching={fetching}
      network="ethereum"
      networkType={chain_id === 1 ? "mainnet" : "kovan"}
      tokenList={tokenList}
    />
  );
};

export default ETHTokenInput;
