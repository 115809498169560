import { useCallback, useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import useLink from "hooks/useLink";
import { getContractById, getLatestTransferAccount } from "utils/web3Link";

const useLatestTransferAccount = () => {
  const [latestAcc, setLatestAcc] = useState("");
  const [fetching, setFetching] = useState(true);
  //   const { account } = useWallet();
  const link = useLink();

  const fetchLastestTransferAccount = useCallback(
    async (account, proxyContractId = "usdt_proxy") => {
      console.log("fetchLastestTransferAccount", proxyContractId, account);

      let proxyContract;

      if (link) {
        proxyContract = getContractById(link, proxyContractId);
      }

      if (account) {
        const nebAcc = await getLatestTransferAccount(proxyContract, account);
        console.log("usdt->nusdt neb address", nebAcc);
        setLatestAcc(nebAcc);
        setFetching(false);
      }
    },
    [link]
  );

  return {
    latestAcc,
    fetchLastestTransferAccount,
    fetchingLastestTransferAccount: fetching,
  };
};

export default useLatestTransferAccount;
