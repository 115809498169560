import axios from "axios";

const chain_id = Number(process.env.REACT_APP_CHAIN_ID);
const baseURL = chain_id === 1  ? 'https://link-backend.nextdao.io' : 'https://link-backend-testnet.nextdao.io';

export const fetchEvents = async ({ page = 0, type = 1, key = "" }) => {
  try {
    const result = await axios.get(
      baseURL + "/link/events",
      {
        params: {
          key,
          page,
          type,
          pageSize: 25,
        },
      }
    );
    return result.data.data;
  } catch (e) {
    console.log("fetchEvents error", e);
    return;
  }
};


export const fetchBridgeFee = async () => {
  try {
    const result = await axios.get(
      baseURL + "/link/estimateGas",
      {
        params: {
          ethAddr:"0x3A40066D1dC27d14C721e4135cF02DCb20C9AFE0",
          nebAddr:"n1dHKZTnMA2hmocLD35UfqG6kwDQ3Uq92nS",
          token:"nUSDT",
          amount: 1,
        },
      }
    );
    return result.data.data;
  } catch (e) {
    console.log("fetchBridgeFee error", e);
    return;
  }
};