import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import useLink from "hooks/useLink";
import { getBalance } from "utils/erc20";
import { getContractById } from "utils/web3Link";
import { getDisplayBalance } from "utils/number";

const useBalance = () => {
  const [balance, setBalance] = useState(0);
  const [fetchingBalance, setFetchingBalance] = useState(true);
  const { account } = useWallet();
  const link = useLink();

  const fetchBalance = useCallback(
    async (tokenContractId = "usdt") => {
      let tokenContract;

      if (link) {
        tokenContract = getContractById(link, tokenContractId);
      }

      if (account) {
        const bal = await getBalance(tokenContract, account);
        const balNum = getDisplayBalance(new BigNumber(bal), 6);
        console.log("usdt balance", balNum, account);
        setBalance(balNum);
        setFetchingBalance(false);
      }
    },
    [link, account]
  );

  useEffect(() => {
    if (!!account) {
      fetchBalance();
    }
    let refreshInterval = setInterval(fetchBalance, 10 * 1000);
    return () => clearInterval(refreshInterval);
  }, [account, fetchBalance]);

  return { balance, fetchBalance, fetchingBalance };
};

export default useBalance;
