import useLink from "hooks/useLink";
import { approve } from "utils/nebLink";
import { toNUSDTBasic } from "utils/nebFormat";

const useNebApprove = () => {
  const link = useLink();

  const handleApprove = async (
    curAllowance,
    accountBalance,
    token_contract_id = "NUSDT",
    spender_contract_id = "LINK_PROXY"
  ) => {
    if (link) {
      const res = await approve(
        link.neb,
        token_contract_id,
        spender_contract_id,
        curAllowance,
        toNUSDTBasic(accountBalance)
      );

      console.log("neb approve", res);

      return res;
    }
  };

  return { onNebApprove: handleApprove };
};

export default useNebApprove;
