// import moment from "moment";
import Web3 from "web3";

const chainId = Number(process.env.REACT_APP_CHAIN_ID);

export const INFURA_PREFIXES = {
  1: "mainnet.",
  3: "ropsten.",
  4: "rinkeby.",
  5: "goerli.",
  42: "kovan.",
};

export const ETHERSCAN_PREFIXES = {
  1: "",
  3: "ropsten.",
  4: "rinkeby.",
  5: "goerli.",
  42: "kovan.",
};

export function getEtherscanLink(data, type) {
  const prefix = `https://${
    ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]
  }etherscan.io`;

  switch (type) {
    case "tx": {
      return `${prefix}/tx/${data}`;
    }
    case "token": {
      return `${prefix}/token/${data}`;
    }
    case "address":
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export const isETHAddress = (address) => {
  const web3 = new Web3();
  return web3.utils.isAddress(address);
};

// start time
// export const START_TIME = moment("2020-09-20 00:00:00.0+00").unix();

// get active status
// export function getPoolActiveStaus() {
//   if(Date.now() >= START_TIME * 1000) {
//     return true;
//   }
//   return true;
// }
