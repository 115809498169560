import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "rebass/styled-components";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

import { ExternalLink } from "theme/components";
import { Spinner } from "theme/components";

import Circle from "assets/loader.svg";

import BTable from "react-bootstrap/Table";
import { useTable } from "react-table";

import { formatAddress, toDatetime } from "utils/format";
import { getEtherscanLink } from "utils/web3";
import { getExplorerLink } from "utils/nebulas";
import { basicToNUSDT } from "utils/nebFormat";

const Pagination = ({ total, onClickPage, page, setPage }) => {


  const pageSize = 15;
  const totalPage = Math.ceil(total / pageSize);
  const pageListArr = Array.from({ length: totalPage }, (x, i) => i);

  const handleClick = (index) => {
    console.log(index);
    setPage(index);
    onClickPage({ page: index });
  };

  return (
    <StyledPaginationWrapper>
      <StyledPaginationList>
        {pageListArr.map((i) => (
          <StyledPaginationItem
            className={page === i ? "active" : ""}
            key={i}
            onClick={() => handleClick(i)}
          >
            {i + 1}
          </StyledPaginationItem>
        ))}
      </StyledPaginationList>
    </StyledPaginationWrapper>
  );
};

const EventTable = ({ type = 1, data, total, fetchData, fetching }) => {

  const { t } = useTranslation("events");

  const columns_eth = [
    {
      Header: t("create-time"),
      accessor: "create_time",
    },
    {
      Header:  `${t("ethereum")}${t("txhash")}`,
      accessor: "eth_hash",
    },
    {
      Header: `${t("nebulas")}${t("txhash")}`,
      accessor: "neb_hash",
    },
    {
      Header: `${t("ethereum")}${t("address")}`,
      accessor: "eth_addr",
    },
    {
      Header:`${t("nebulas")}${t("address")}`,
      accessor: "neb_addr",
    },
    {
      Header: t("swap-value"),
      accessor: "eth_amount",
    },
    {
      Header: t("recipient-value"),
      accessor: "neb_amount",
    },
    {
      Header: t("status"),
      accessor: "status",
    },
  ];

  const columns_neb = [
    {
      Header: t("create-time"),
      accessor: "create_time",
    },
    {
      Header: `${t("nebulas")}${t("txhash")}`,
      accessor: "neb_hash",
    },
    {
      Header: `${t("ethereum")}${t("txhash")}`,
      accessor: "eth_hash",
    },
    {
      Header: `${t("nebulas")}${t("address")}`,
      accessor: "neb_addr",
    },
    {
      Header: `${t("ethereum")}${t("address")}`,
      accessor: "eth_addr",
    },
    {
      Header: t("swap-value"),
      accessor: "neb_amount",
    },
    {
      Header:  t("recipient-value"),
      accessor: "eth_amount",
    },
    {
      Header: t("fee"),
      accessor: "tax",
    },
    {
      Header: t("status"),
      accessor: "status",
    },
  ];

  const [page, setPage] = useState(0);

  let column_select;

  // define columns
  if (type === 1) {
    // eth -> neb
    column_select = columns_eth;
  } else {
    // type === 2
    // neb -> eth
    column_select = columns_neb;
  }

  const columns = React.useMemo(() => column_select, [type]);

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const PendingText = "-";

  return (
    <StyledWrapper>
      {!fetching ? (
        <>
          <BTable striped hover {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render("id") === "eth_hash" ? (
                            cell.value ? (
                              <StyledLink
                                href={getEtherscanLink(cell.value, "tx")}
                              >
                                {cell.value && formatAddress(cell.value, 5)}
                              </StyledLink>
                            ) : (
                              PendingText
                            )
                          ) : cell.render("id") === "neb_hash" ? (
                            cell.value ? (
                              <StyledLink
                                href={getExplorerLink(cell.value, "tx")}
                              >
                                {cell.value && formatAddress(cell.value, 5)}
                              </StyledLink>
                            ) : (
                              PendingText
                            )
                          ) : cell.render("id") === "eth_addr" ? (
                            <StyledLink
                              href={getEtherscanLink(cell.value, "address")}
                            >
                              {cell.value && formatAddress(cell.value, 5)}
                            </StyledLink>
                          ) : cell.render("id") === "neb_addr" ? (
                            <StyledLink
                              href={getExplorerLink(cell.value, "address")}
                            >
                              {cell.value && formatAddress(cell.value, 5)}
                            </StyledLink>
                          ) : cell.render("id") === "neb_amount" ? (
                            cell.value ? (
                              `${basicToNUSDT(cell.value)} nUSDT`
                            ) : (
                              PendingText
                            )
                          ) : cell.render("id") === "eth_amount" ? (
                            cell.value ? (
                              `${basicToNUSDT(cell.value)} USDT`
                            ) : (
                              PendingText
                            )
                          ) : cell.render("id") === "tax" && type === 2 ? (
                            cell.value && `${basicToNUSDT(cell.value)} nUSDT`
                          ) : cell.render("id") === "create_time" ? (
                            cell.value && (
                              <span data-tip={cell.value}>
                                {toDatetime(cell.value)}
                              </span>
                            )
                          ) : cell.render("id") === "status" ? (
                            cell.value === 0 ? (
                              t("fail")
                            ) : cell.value === 1 ? (
                              t("success")
                            ) : cell.value === 2 ? (
                              t("pending")
                            ) : (
                              ""
                            )
                          ) : (
                            cell.render("Cell")
                          )}

                          <ReactTooltip />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </BTable>

          <Pagination
            total={total}
            page={page}
            setPage={setPage}
            onClickPage={fetchData}
          />
        </>
      ) : (
        <StyledLoadingWrapper>
          <CustomLightSpinner src={Circle} alt="loader" size={"90px"} />
          <StyledLoadingText>{t("loading")}</StyledLoadingText>
        </StyledLoadingWrapper>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  overflow-x: scroll;
`}
`;

const StyledLink = styled(ExternalLink)`
  font-weight: 400;
`;

const StyledLoadingWrapper = styled(Flex)`
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLoadingText = styled(Text)`
  margin-top: 12px;
  color: ${({ theme }) => theme.color.text1};
`;

const CustomLightSpinner = styled(Spinner)`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const StyledPaginationWrapper = styled.div`
  margin: 24px 0;
`;

const StyledPaginationList = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledPaginationItem = styled.button`
  margin: 0 6px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.color.bg4};
  color: ${({ theme }) => theme.color.text1};
  cursor: pointer;
  height: 24px;
  font-size: 14px;
  &.active {
    background-color: ${({ theme }) => theme.color.bg4};
  }
`;

export default EventTable;
