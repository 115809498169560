import { useState, useEffect } from "react";
import { fetchBridgeFee } from "utils/api";
import { toUSD } from "utils/format";

const useBridgeFee = () => {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);

  const fetchBridgeFeeData = async () => {
    setFetching(true);

    const result = await fetchBridgeFee();
    const zoom_rate = 1.2;

    if (result) {
      console.log("fetchBridgeFee", result);
      setData({
        ...result,
        feeUSD: toUSD(result.taxFee),
        atLeastFeeUSD: toUSD(result.taxFee)*zoom_rate,
      });
    }

    setFetching(false);
  };

  useEffect(() => {
    fetchBridgeFeeData();
  }, [])



  return { feeData: data, fetching };
};

export default useBridgeFee;
