import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import { Flex, Box, Text } from "rebass/styled-components";
import metamaskLogo from "assets/metamask-fox.svg";
import { useTranslation } from 'react-i18next';
// import walletConnectLogo from 'assets/wallet-connect.svg'

import Button from "components/Button";
import Modal from "components/Modal";
import ModalActions from "components/ModalActions";
import ModalContent from "components/ModalContent";
import ModalTitle from "components/ModalTitle";

import WalletCard from "./components/WalletCard";

const WalletProviderModal = ({ onDismiss, onConnectedCallback = () => {} }) => {

  const { t } = useTranslation();
  const { account, status, error, chainId, connect } = useWallet();
  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  useEffect(() => {
    if (account) {
      onDismiss();
      onConnectedCallback(account);
    }
  }, [account, onConnectedCallback, onDismiss]);

  const handleOnConnect = () => {
    connect("injected");
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalTitle text={t("select-an-ethereum-wallet")} />

      <ModalContent>
        <StyledWalletsWrapper>
          <StyledWalletCard>
            <WalletCard
              icon={metamaskLogo}
              onConnect={handleOnConnect}
              title="Metamask"
            />
          </StyledWalletCard>
        </StyledWalletsWrapper>

        {error && error.name === "ChainUnsupportedError" ? (
          <StyledErrorMsg>Must select Ethereum {chain_id !== 1 ? "Kovan" : "Mainnet"} Network.</StyledErrorMsg>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledWalletCard = styled.div``;

const StyledErrorMsg = styled(Text)`
  margin-top: 6px;
  color: ${({ theme }) => theme.color.error};
`;

export default WalletProviderModal;
