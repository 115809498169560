import { useCallback, useEffect, useState } from "react";
import useLink from "hooks/useLink";
import { getAllowance } from "utils/nebLink";

const useNebAllowance = (nebAccount) => {
  const [nebAllowance, setNebAllowance] = useState(0);

  const link = useLink();

  const fetchNebAllowance = useCallback(
    async (token_contract_id = "NUSDT", spender_contract_id = "LINK_PROXY") => {
      if (nebAccount && link) {
        const allowance = await getAllowance(
          link.neb,
          token_contract_id,
          spender_contract_id,
          nebAccount
        );
        setNebAllowance(allowance);

        // console.log("fetchNebAllowance", allowance, nebAccount);
      }
    },
    [nebAccount, link]
  );

  useEffect(() => {
    fetchNebAllowance();

    const timer = setInterval(fetchNebAllowance, 5 * 1000);
    return () => clearInterval(timer);
  }, [link, fetchNebAllowance]);

  return { nebAllowance, fetchNebAllowance };
};

export default useNebAllowance;
