import React, { useState, useCallback } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

// ether
import { useWallet } from "use-wallet";
import useBalance from "hooks/useBalance";
import useLatestTransferAccount from "hooks/useLatestTransferAccount";
import useApprove from "hooks/useApprove";
import useAllowance from "hooks/useAllowance";
import useStake from "hooks/useStake";

// modal
import useModal from "hooks/useModal";
import TransactionConfirmationModal from "components/TransactionConfirmationModal";
import WalletProviderModal from "components/WalletProviderModal";

const ETHSwapButton = ({ params }) => {

    const { fromValue, recpAddr, inputDisabled, recpAddrInvalid, inputError } = params;
  // i18n
  const { t } = useTranslation();
  // eth
  const { account } = useWallet();
  const { latestAcc, fetchLastestTransferAccount } = useLatestTransferAccount();
  const [onPresentWalletProviderModal] = useModal(
    <WalletProviderModal onConnectedCallback={fetchLastestTransferAccount} />
  );

  const { onApprove } = useApprove();
  const allowance = useAllowance("usdt_proxy", "usdt");
  const { onStake } = useStake("usdt_proxy");
  const { balance, fetchingBalance } = useBalance();

  // eth: modal and loading
  const [showConfirm, setShowConfirm] = useState(false);
  // clicked confirm
  const [attemptingTxn, setAttemptingTxn] = useState(false);
  // txn values
  const [txHash, setTxHash] = useState("");

  const handleClickApprove = () => {
    onApprove("usdt_proxy", "usdt");
  };

  const handleClickSwap = async () => {
      // use web3 to call swap
      // show transcation confirm modal
      setShowConfirm(true);
      setAttemptingTxn(true);

      // start send tx
      setTxHash(await onStake(fromValue, recpAddr));
      setAttemptingTxn(false);
  };


  // handle dismiss eth transcation confirm modal
  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if send tx success, close borrow modal
    if (txHash) {
      // refresh page
      window.location.reload();
    }
    setTxHash("");
  }, [txHash]);

  return (
    <div>
      {!account && (
        <Button
          size="lg"
          onClick={onPresentWalletProviderModal}
          text={t("connect-ethereum-wallet")}
        />
      )}

      {!!account &&
        (allowance > 0 ? (
          <Button
            size="lg"
            onClick={handleClickSwap}
            disabled={inputDisabled || recpAddrInvalid ? "disabled" : null}
            text={inputError ? inputError : t("swap")}
          />
        ) : (
          <Button size="lg" onClick={handleClickApprove} text="Approve USDT" />
        ))}

      {/* eth transaction confirm modal */}
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
      />
    </div>
  );
};

export default ETHSwapButton;
